@import "style/variables";

.article {
	width: 100%;
	max-width: 400px;
	height: 450px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	border: $background 1px solid;
	border-radius: 15px;

	.name {
		text-align: center;
		font-family: $fInter;
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: 22px;
	}

	.image {
		max-height: 40%;
		border-radius: 8px;
	}

	.text {
		text-align: center;
		ul {
			text-align: left;
			margin-left: 20px;
		}
	}

	.button-send {
		width: 100%;
		text-decoration: none;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		text-align: center;
		font-family: $fInter;
		background-color: $gray;
		color: $text-color;
		transition: all 0.5s;

		border-radius: 4px;
		padding: AdaptiveClamp(11, 13) AdaptiveClamp(21, 31);

		&:hover {
			border-radius: 8px;
			background-color: $light-gray;
		}
	}
}
