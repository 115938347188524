@import "style/variables";

.contacts {
	display: inline;

	.title.page-name {
		padding: 30px $page-clamp;
		background-color: $green;
	}

	.info {
		padding: 70px $page-clamp;

		.annotation {
			text-align: center;
		}

		.block {
			padding: 50px 0;
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
			gap: 50px;

			.card {
				.copy {
					color: $green;
					text-align: center;
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 5px;

					&:hover,
					&:active {
						color: $light-green;
					}

					.messenger {
						height: AdaptiveClamp(10, 15);
					}
				}
			}
		}
	}
}
