//Inter

@font-face {
	font-family: "Inter";
	src:
		local("Inter Bold"),
		local("Inter-Bold"),
		url("./Inter/Inter-Bold.woff2") format("woff2");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src:
		local("Inter Regular"),
		local("Inter-Regular"),
		url("./Inter/Inter-Regular.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src:
		local("Inter Medium"),
		local("Inter-Medium"),
		url("./Inter/Inter-Medium.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

//Roboto

@font-face {
	font-family: "Roboto";
	src:
		local("Roboto"),
		local("Roboto-Regular"),
		url("./Roboto/Roboto-Regular.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Roboto";
	src:
		local("Roboto Medium"),
		local("Roboto-Medium"),
		url("./Roboto/Roboto-Medium.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Roboto";
	src:
		local("Roboto Bold"),
		local("Roboto-Bold"),
		url("./Roboto/Roboto-Bold.woff2") format("woff2");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
