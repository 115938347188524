@import "style/variables";

.nav-button {
	text-decoration: none;
	transition: all 0.2s;

	&:visited {
		text-decoration: none;
	}

	&.text {
		color: $text-color;

		&:hover.text,
		&:active.text,
		&.active.text {
			color: $green;
		}
	}

	&.button {
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		text-align: center;
		font-family: $fInter;

		border-radius: 4px;
		padding: AdaptiveClamp(11, 13) AdaptiveClamp(21, 31);

		&:hover,
		&:active,
		&.active {
			border-radius: 8px;
		}

		&.btn-gray {
			background-color: $gray;
			color: $text-color;

			&:hover,
			&:active,
			&.active {
				background-color: $light-gray;
			}
		}

		&.btn-green {
			background-color: $green;
			color: $white;

			&:hover,
			&:active,
			&.active {
				background-color: $light-green;
			}
		}
	}

	&.full-width {
		width: 100%;
	}
}
