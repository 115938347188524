@import "style/variables";

.luch {
	.title.page-name {
		padding: 30px $page-clamp;
		background-color: $green;
	}

	.logic {
		display: flex;
		flex-direction: column;
		gap: 30px;
		padding: 70px $page-clamp;
		background-color: $white;

		.list {
			list-style-type: disc;
			& > li {
				margin-left: 20px;
			}
		}
	}

	.features {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
		justify-items: center;
		align-items: center;
		gap: 15px;

		padding: 70px $page-clamp;
		background-color: $background;

		.text-block {
			display: flex;
			flex-direction: column;
			gap: 30px;

			.list {
				list-style-type: disc;

				& > li {
					margin-left: 20px;
				}
			}
		}

		.image {
			max-height: 350px;
		}
	}

	.analytics {
		padding: 70px $page-clamp;
		background-color: $background;

		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
		justify-items: center;
		align-items: center;
		gap: 15px;

		.text-block {
			display: flex;
			flex-direction: column;
			gap: 30px;

			.list {
				list-style-type: disc;

				& > li {
					margin-left: 20px;
				}
			}
		}

		.image-block {
			display: flex;
			flex-direction: column;
			gap: 50px;
			align-items: center;

			& > img {
				max-height: 200px;
			}
		}
	}

	.base-text {
		display: flex;
		flex-direction: column;
		gap: 30px;
		padding: 70px $page-clamp;
		background-color: $white;

		.function {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
			justify-items: center;
			align-items: center;
			gap: 15px;

			.image {
				max-height: 350px;
			}

			.text-block {
				.list {
					list-style-type: disc;
					& > li {
						margin-left: 20px;
					}
				}
			}
		}
	}
}
