@import "style/variables";

.text {
	font-family: $fInter;
	font-size: AdaptiveClamp(16, 18);
	font-style: normal;
	font-weight: 400;
	line-height: 24.96px;

	&.white {
		color: $white;
	}

	&.black {
		color: $text-color;
	}
}
