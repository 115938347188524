@import "style/variables";

.header {
	padding-top: AdaptiveClamp(8, 16);
	width: 100%;
	background-color: $black;

	.navigation {
		display: flex;
		justify-content: space-between;
		gap: 25px;
		flex-direction: row;
		align-items: center;
		padding: 5px $page-clamp;
		height: AdaptiveClamp(60, 100);
		background-color: $white;

		.logo {
			height: AdaptiveClamp(40, 80);
			object-fit: scale-down;
		}

		.list {
			width: 600px;
			gap: 10px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			@media screen and (width <= 600px) {
				& {
					display: none;
				}
			}

			& > * {
				text-align: center;
			}
		}

		.burger {
			display: none;

			@media screen and (width <= 600px) {
				display: block;
			}
		}
	}

	.nav-mobile {
		visibility: hidden;
		pointer-events: none;
		z-index: $blur-index;
		position: fixed;
		inset: 0;
		transition: all 0.5s;

		&.blur {
			visibility: visible;
			pointer-events: auto;
			backdrop-filter: blur(2px);
		}

		.menu {
			position: fixed;
			height: 100dvh;
			width: min(90dvw, 300px);

			top: 0;
			right: min(-90dvw, -300px);

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 25px;

			background-color: $background;
			z-index: $menu-index;
			transition: all 0.4s;

			&.open {
				top: 0;
				right: 0;
			}

			.cross {
				position: absolute;
				top: 30px;
				left: 30px;
			}

			.list {
				display: flex;
				flex-direction: column;
				gap: 20px;

				& > li {
					font-weight: 500;
				}
			}
		}
	}
}
