@import "style/variables";

.home {
	.welcome {
		display: flex;
		flex-direction: column;
		gap: AdaptiveClamp(20, 44);
		padding: 50px $page-clamp;
		position: relative;
		overflow: hidden;

		.video {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: 100%;
			min-width: 100%;
			min-height: 100%;
			object-fit: cover;
			transform: translate(-50%, -50%);
			z-index: -1;
		}

		.title {
			max-width: 632px;
			text-align: left;
		}

		.text {
			max-width: 632px;
			font-size: AdaptiveClamp(14, 18);
			font-weight: 500;
		}

		.button-box {
			max-width: 632px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.short-about {
		margin: 50px $page-clamp;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;

		.facts {
			width: 100%;

			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			justify-items: center;
			flex-wrap: wrap;
			gap: 30px;
		}
	}

	.partners {
		background-color: $light-green;
		padding: AdaptiveClamp(40, 70) 0;
		display: flex;
		flex-direction: column;
		gap: 50px;

		.marquee {
			.title {
				margin-left: 50px;
			}

			.logo-img {
				height: 50px;
				margin-left: 50px;
			}

			.logo-img.x2 {
				height: 100px;
			}
		}
	}
}
