.footer {
	width: 100%;
	height: 80px;
	padding: 5px;
	margin-top: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #fff;
	background-color: #252525;
}
