@import "style/variables";

.fact {
	display: flex;
	flex-direction: column;
	justify-items: stretch;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	gap: 10px;

	.image {
		width: 128px;
		height: 128px;
	}

	.title {
		max-width: 310px;
	}

	.text {
		max-width: 310px;
	}

	.text.text-center {
		text-align: center;
	}
}
