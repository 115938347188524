@import "style/variables";

.title {
	font-family: $fRobot;
	text-align: center;

	&.black {
		color: $black;
	}

	&.white {
		color: $white;
	}

	&.h1 {
		font-size: AdaptiveClamp(34, 72);
		font-style: normal;
		font-weight: 500;
		line-height: AdaptiveClamp(45, 72);
	}

	&.h2 {
		font-size: AdaptiveClamp(26.265, 37.5);
		font-style: normal;
		font-weight: 500;
		line-height: AdaptiveClamp(33.42, 48);
	}

	&.h3 {
		font-size: AdaptiveClamp(16, 25);
		font-style: normal;
		font-weight: 700;
		line-height: 40px;
	}
}
