@import "../fonts/fonts";

// FONTS

$fInter: "Inter", sans-serif;

$fRobot: "Roboto", sans-serif;

// COLORS

$green: #4eb5ad;
$light-green: #5fc5bd;
$gray: #cccccc;
$light-gray: #edeef2;
$white: #ffffff;
$black: #000000;
$text-color: #252525;
$background: #edeef2;

// INDEX
$under-site: -1;
$blur-index: 10;
$menu-index: 11;

//CLAMPS

$page-clamp: clamp(
	15px,
	calc(15px + (150 - 15) * ((100dvw - 320px) / (1920 - 320))),
	150px
);

@function AdaptiveClamp($x, $y) {
	@return clamp(
		#{$x}px,
		calc(#{$x}px + (#{$y} - #{$x}) * ((100vw - 320px) / (1920 - 320))),
		#{$y}px
	);
}
