@import "style/variables";

.about {
	.title.page-name {
		padding: 30px $page-clamp;
		background-color: $green;
	}

	.description {
		padding: 70px $page-clamp;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
		justify-items: center;
		align-items: center;
		gap: 15px;

		.image {
			max-height: 360px;
		}

		.text {
			font-size: AdaptiveClamp(16, 18);
			text-align: justify;
		}
	}

	.advantages {
		background-color: $background;
		padding: 70px $page-clamp;
		display: flex;
		flex-direction: column;
		gap: 40px;

		.list {
			list-style-type: disc;

			& > li {
				margin-left: 20px;
			}
		}
	}

	.experience {
		background-color: $white;
		padding: 70px $page-clamp;
		display: flex;
		flex-direction: column;
		gap: 40px;

		.list {
			list-style-type: disc;

			& > li {
				margin-left: 20px;

				& > .text {
					font-size: AdaptiveClamp(16, 18);
				}
			}
		}
	}

	.numbers {
		background-color: $background;
		padding: 70px $page-clamp;
		display: flex;
		flex-direction: column;
		gap: 40px;

		.list {
			list-style-type: disc;

			& > li {
				margin-left: 20px;

				& > .text {
					font-size: AdaptiveClamp(16, 18);
				}
			}
		}
	}

	.reviews {
		display: flex;
		gap: 40px;
		flex-direction: column;
		padding: 70px $page-clamp;
	}

	.buy-list {
		background-color: $background;
		padding: 70px $page-clamp 40px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;

		.name {
			.title {
				text-align: left;
			}
		}
	}
}
