@import "style/variables";

.catalog {
	.title.page-name {
		padding: 30px $page-clamp;
		background-color: $green;
	}

	.articles {
		padding: 70px $page-clamp;
		display: flex;
		gap: 50px;
		flex-direction: column;

		.items {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			justify-items: center;
			flex-wrap: wrap;
			gap: 30px;
		}
	}

	.goals {
		padding: 50px $page-clamp;
	}

	.tip {
		padding: 50px $page-clamp;
		text-align: right;
	}
}
