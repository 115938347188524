@import "style/variables";

.review {
	display: flex;
	justify-content: left;
	align-items: flex-start;
	flex-direction: column;
	gap: 20px;

	.image {
		width: 48px;
		height: 48px;
	}

	.base-text {
		margin-left: 20px;
	}

	.title {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.name {
			font-family: $fRobot;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 22px;
		}

		.type {
			font-family: $fRobot;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
		}
	}
}
